import React from "react";
// Customizable Area Start
import Trophies from "./Trophies.web";
import { Box, Typography, styled, Backdrop, Button, IconButton } from "@material-ui/core";
import NavigateLayout from "../../../components/src/NavigateLayout";
import CenterCard from "../../../components/src/CenterCard.web";
import LandingPageController from "./LandingPageController.web";
import { groupDecoration, groupMultilevel, groupscroll, listIcon, groupAvtar, imageFlag, buttonNotification, rectangle, groupMaskMath, Search, Back, backImage,skipHistory, crossButton, loader} from './assets';
import Median from "./Median.web";
import Grade from "./Grade.web"
import Lessons from "./Lessons.web";
import { withTranslation } from "react-i18next";
import GradeList from "./GradeList.web";
// Customizable Area End

class LandingPageWeb extends LandingPageController {
  
  // Customizable Area Start

  renderContent() {
    const renderGradeList = () => (
      <Box style={{ backgroundColor: '#fff', width: '100%', height:'100%' }}>
        <GradeList
          data-testid="GradeList"
          navigation={this.props.navigation}
          id={""}
          selectGraphClose={this.handleSearchGraphClose}
          countryId={parseInt(this.state.country_id)}
          selectedGrade={this.state.selectedGrade}
          sendDataToCategories={this.handleGradeId}
        />
      </Box>
    );

    const renderGrade = () => (
      <Grade
        navigation={this.props.navigation}
        id={""}
        data-testid="Grade"
        searchedGraph= {this.state.searchedGraph}
        gradeOpen={this.state.gradeOpen}
        closeDrawer={this.closeDrawer}
        sendDataToParent={this.receiveDataFromChild}
        sendDataToLanding={this.receieveData}
        grade_id={parseInt(this.state.gradeId)}
      />
    );

    const renderLessons = () => (
      <Lessons data-testid="lessons" lessonData={this.state.lessonList} progressStart= {this.state.progressStart} navigation={this.props.navigation} />
    );
  
    const renderDefaultContent = () => (
      <Box className="rightcard-inner1">
        <img src={this.state.readyTeddy} width="102px" />
        <Typography className="readyText">Learn It's about not giving up!</Typography>
        <Button variant="contained" className="login-btn" type="submit" onClick={this.toggleState}>{this.props.t("Get started!")}</Button>
      </Box>
    );

    const moreCondition = () => {
      if (this.state.lessonParentId && this.state.lessonList?.length > 0 && !this.state.showGivingUpText) {
        return renderLessons();
      } 
      return renderDefaultContent();
    };
    
    if (this.state.showGradeList) {
      return renderGradeList();
    }
    if (this.state.showGrade) {
      return renderGrade();
    }
    return moreCondition();
  }

  renderCenterPart() {
    if(this.state.showGradeList || this.state.showGrade && !this.state.centerShown ) {
      return(
        <Box className="settingBackground" data-testid="center-part">
        <Box className="settingImgWrapper" >
          <Box className="setCenter" >
            <Box>
              <IconButton data-testid="back-btn" onClick={this.goBack}>
                <img src={Back} width="20px" height="22px" className="BackHomeImage"/>
              </IconButton>
            </Box>
            <Box className="iconContainerscrollNew">
              <img src={this.state.avatarHealthIcon} width="51px" />
              <Box>
                <Box className="scrollboxtitlewrapper">
                  <Typography className="fontSetTop">{this.state.selectCat}</Typography>
                </Box>
                <Typography className="scrollboxdetailOne">{this.props.t(this.state.selectSubCat)}</Typography>
              </Box>
            </Box>
            <Box className="languageButton1" style={{ marginTop: '15px' }}>
              <IconButton >
                <img src={Search} width="31px" height="22px" data-testid="searchIcon" onClick={() => this.openSearch()}/>
              </IconButton>
            </Box>
          </Box>
          <Box className="nomargin">
          </Box>
        </Box>
      </Box>
      )
    } 
    else {
      return (
        <><CenterCard data-testid="CenterCard" Topic={this.state.topicName} Unit={this.state.unitName} openDrawer={this.state.openDrawer} trophiesOpen={this.state.trophiesOpen} goalsOpen={this.state.goalsOpen} medianOpen={this.state.medianOpen} toggleState={this.toggleState} handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)} navigation={this.props.navigation} />
        <Trophies data-testid="trophies" stateOpen={this.state.trophiesOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer} />
        <Median data-testid="median" stateOpen={this.state.medianOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer} /></>
      )
    }
  }

  renderComponent() {
    return(
      <SettingContainer>
       {this.state.isLoading ? (
        <Box className="loaderShown">
        <img src={loader} alt="Loading" />
      </Box>
    ) : 
      <Box className="mainContentWrapper">
        <Box className="settingBackground">
          <Box className={this.state.backImage ? "settingImgWrapper" : "settingImgWrapperNew"}>
            <Box className="nomargin">
            {this.renderCenterPart()}
             </Box>
          </Box>
          {this.state.centerShown &&
            <Box className={this.state.isScrolled ? "showblock" : "hiddenBlock"}>
              <Box className="scrollBox" >
                <Box className="iconContainerscroll">
                  <img src={groupAvtar} width="51px" />
                  <Box>
                    <Box className="scrollboxtitlewrapper">
                      <img src={groupMultilevel} width="16px" />
                      <Typography className="fontSet">{this.state.selectCat}</Typography>
                    </Box>
                    <Typography className="scrollboxdetail" >{this.props.t(this.state.selectSubCat)}</Typography>
                  </Box>
                </Box>
                <Box className="languageButton1">
                  <img src={imageFlag} width="31px" height="22px" />
                  <img src={buttonNotification} width="20.6px" height="24px" />
                </Box>
              </Box>
            </Box>}
        </Box>
        <Box className="mainLanding">
          {this.renderContent()}
        </Box>
        {this.state.guideMessage &&
          <Backdrop className="backdrop" open={this.state.guideMessage}>
            <Multilevel>
              <Button className="MultilevelButton" >
                <img src={listIcon} className='multiLevel_1' />
              </Button>
              <Typography className="subjectName_1">{this.props.t('Pick your subject here and start your journey!')} </Typography>
            </Multilevel>
          </Backdrop>}
      </Box>
       }
    </SettingContainer>
    )}
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
      return (  
        // Customizable Area Start
        <div style={{display:'flex', height:'100%'}}>
         { window.innerWidth <= 959 && this.state.showGrade ? this.renderComponent() : <NavigateLayout menuName="Home">
            {this.renderComponent()}
       </NavigateLayout> } 
        </div>
      // Customizable Area End
      );
    }
}
// Customizable Area Start
export default withTranslation()(LandingPageWeb)
const Multilevel: React.ComponentType = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: '15px',
  "@media(max-width: 959px)": {
    position: 'absolute',
    top: '33%'
  },
  "& .MultilevelButton": {
    "& .MuiButton-label": {
      width: '50px',
      height: '50px',
    }
  },
  "& .subjectName_1": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "21px",
    color: '#fff'
  }
}))
const SettingContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  "@media(max-width: 959px)": {
    backgroundColor: '#fff',
  },
  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(100% - 240px)",
     marginLeft: "240px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },
  '& .centerLoader': {
     display:'flex',
     height:'100%'
  },
  '& .showblock': {
    display: 'block'
  },
  '& .hiddenBlock': {
    display: 'none'
  },
  '& .nomargin': {
    width: '100% !important',
  },
  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  '& .settingBackground': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .settingBackgroundSearch': {
    backgroundColor: '#fff',
    height: 'auto', 
    width: '50%',
    "@media(max-width: 959px)": {
      backgroundColor: '#fff',
    height: 'auto', 
    zIndex: 999,
    width: '100%'
    }
  },
  '& .fontSet': {
    color: 'var(--primary-blue-blue-shadows, #00AEFF)',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '18.5px',
    letterSpacing: '0.128px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    width:'160px'
  },
  '& .fontSetTop': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '18.5px',
    letterSpacing: '0.128px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    width:'160px'
  },
  '& .mainLanding': {
    flex: 6,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    '& .MuiBox-root': {
      "@media(max-width: 959px)": {
        borderRadius: '25px',
      }
    },
    "@media(max-width: 959px)": {
      width: '100%',
      borderRadius: '25px 25px 0px 0px',
      marginTop: '0px', 
      height:'auto'
    }
  },
  '& .iconContainerscroll': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    height: '65px',
    display: 'flex',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    marginTop: "60px",
    alignItems: "center",
    gap: '15px',
    padding: "10px",
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)'
  },

  "& .BackHomeImage":{
     display:"none",
     "@media(max-width:960px)":{
      marginTop:"4px",
      display:"flex",
      filter:" brightness(0) invert(1)"
     }
  },
  '& .iconContainerscrollNew': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    height: '65px',
    display: 'flex',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    alignItems: "center",
    gap: '15px',
    padding: "10px",
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)'
  },
  '& .setCenter': {
    display: 'flex',
    margin: 'auto',
    marginTop: '50%',
    "@media(max-width: 959px)": {
      marginTop: '60px'
    }


  },
  '& .scrollBox': {

    display: "flex",
    padding: "10px",
    minHeight: "150px",
    alignItems: "baseline",
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundImage: `url(${groupscroll})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100%",
    gap: '10px',
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  '& .scrollboxtitlewrapper': {
    display: 'flex',
    gap: '10px',
    color: "#00AEFF",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "18.5px",
    letterSpacing: "0.128px"
  },
  '& .scrollboxdetail': {
    color: "#7F7F7F",
    fontFamily: "Nunito",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "15px",
    letterSpacing: "0.104px",
    marginTop: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    width: '160px'
  },
  '& .scrollboxdetailOne': {
    color: 'var(--primary-blue-blue-shadows, #00AEFF)',
    fontFamily: "Nunito",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "15px",
    letterSpacing: "0.104px",
    marginTop: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    width:'160px'
  },
  '& .languageButton1': {
    display: 'flex',
    gap: '10px',
    position: "relative", top: "-12px"
  },
  '& .readyText': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0.144px',
    flexShrink: 0,
    marginTop: '10px'
  },
  "& .login-btn": {
    color: "white",
    textAlign: "center",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.8px",
    backgroundColor: '#36BFFF',
    width: '260px',
    textTransform: 'none',
    height: '44px',
    boxShadow: '0px 4px #00AEFF',
    borderRadius: '16px',
    marginTop: '35px',
    "@media (max-width:600px)": {
        width: '260px',
        fontSize: '16px'
    },
  },
  ' & .readyTextSearch' : {
    flexShrink: 0,
    marginTop: '16.39',
    color: "var(--primary-neutrals-warm-gray-300, #B3B3B3)",
    textAlign: "center",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.128px",
  },
  '& .settingImgWrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#00AEFF',
    borderBottomRightRadius: '0px',
    height: '100vh',
    backgroundSize: '60%',
    backgroundImage: `url(${groupMaskMath})`,
    "@media(max-width: 1800px)": {
    backgroundSize: '50%',
    },

    "@media(max-width: 959px)": {
      minHeight: "180px",
      height: 'auto',
      backgroundImage: `url(${backImage})`,
      backgroundSize: '100%',
    }
  },
  '& .settingImgWrapperNew': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#00AEFF',
    borderBottomRightRadius: '0px',
    height: '100vh',
    "@media(max-width: 959px)": {
      minHeight: "180px",
      height: 'auto',
      backgroundImage: `url(${backImage})`,
      backgroundSize: '100%',

    }
  },
  "& .rightcard-inner1":
  {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: ' var(--primary-neutrals-warm-gray-10, #F7F7F7)',
    backgroundImage: `url(${groupDecoration})`,
    "@media(max-width: 959px)": {
      width: '100%',
      height: '585px'
    }
  },
 
  

  [theme.breakpoints.up('md')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
    '& .settingBackground': {
      flex: 5,
      height: 'auto'
    },
    '& .settingImgWrapper': {
      paddingTop: 'unset',
      height: '100vh',
      borderBottomRightRadius: '20px',

    },
    '& .nomargin': {
      margin: 'auto !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      width: '100% !important',
      margin: 0,
    }
  }
}))
// Customizable Area End
